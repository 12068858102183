import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Footer.css';



import WESLogo from '../Media/WESLogo.png';

const Footer = () => {

    const navigate = useNavigate(); // Step 2: Use the useNavigate hook
    return (
        <footer>
            <div className='f-left'>
            <a href="mailto:hello@weirdeyestudios.com">hello@weirdeyestudios.com</a>
            </div>
            <div className='f-right'>
                    <button onClick={() => {navigate('/privacy'); }}>Privacy</button>

</div>
        </footer>
    );
};

export default Footer;