import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


import './App.css';

import Privacy from './Privacy/Privacy';
import CardPage from './Card/CardPage';
import ServicePage from './Services/ServicePage';
import TryPage from './Try/Try';
import Contact from './Contact/ContactPage';

import Home from './Home/Home';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/physical' element={<CardPage />} />
        <Route path='/services' element={<ServicePage />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/try' element={<TryPage />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
