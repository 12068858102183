import React from 'react';
import './ReviewComponent.css';

import FiveStars from './FiveStars.png';

const ReviewComponent = ({ image, text }) => {
    return (
        <div>
                        <img src={image} alt="Review" className='rs-card'/>
        <div className='rs-base'>
            <img src={FiveStars} alt="Five Stars" className='rs-stars'/>
            <p className='rs-r'>"{text}"</p>
            <p> - Verified Etsy Buyer</p>
        </div>
        </div>
    );
};

export default ReviewComponent;