import React, { useEffect, useState } from 'react';
import { bootstrapCameraKit, createMediaStreamSource, Transform2D } from "@snap/camera-kit";

import attributionlogo from './attribution.png'

import "./CameraKitModule.css"; 

const CameraComponent = ({currentLens, onUpdateLens   }) => {
  const [canvasSize, setCanvasSize] = useState({ width: 400, height: 400 });
  const [borderStyle, setBorderStyle] = useState('0px solid #fff');
  const [isLoaded, setIsLoaded] = useState(false);
  const [getSession, setSession] = useState(null);
  const [myLenses, setLenses] = useState(null);
  useEffect(() => {
    const initializeCamera = async () => {

      if(getSession != null){
        //update the lens
        console.log("updating lens")
        console.log(currentLens)
        var localSession = getSession;
        var localLenses = myLenses;
        await localSession.applyLens(localLenses[currentLens]);
        handleImageLoad();
        return;
      }

      
      try {
        const apiToken = "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNjk5ODIwMDI2LCJzdWIiOiIwNzZlOGMxZi1mMzZlLTQxZTUtOGI1ZS02YjY4MTA3NGZiODN-UFJPRFVDVElPTn40NzdiNDIwYi00M2RlLTQ0ZjktYTIwMS1lMjE2NGU0OWFmNGUifQ.ife1IChvqzSe_B22AwDUMuSaXhcShyZwmUhafmHWdPs";
        const cameraKit = await bootstrapCameraKit({ apiToken });

        const canvas = document.getElementById("my-canvas");
        const session = await cameraKit.createSession({ liveRenderTarget: canvas });
        setSession(session);
        session.events.addEventListener('error', (event) => {
          if (event.detail.error.name === 'LensExecutionError') {
            console.log('The current Lens encountered an error and was removed.', event.detail.error);
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        const source = createMediaStreamSource(stream, { transform: Transform2D.MirrorX, cameraType: 'front' });
        await session.setSource(source);
        await source.setRenderSize(1000, 2000);
        const lensToLoad = "6bfb77aa-6919-421d-ace3-a64e10f196b4";
        console.log(lensToLoad);
        const { lenses } = await cameraKit.lensRepository.loadLensGroups([
          lensToLoad
        ]);

        setLenses(lenses);
        console.log(lenses.length)


        await session.applyLens(lenses[currentLens]);


        canvas.focus();
        await session.play();
        setBorderStyle('20px solid #fff');
        setIsLoaded(true);
        console.log("Lens rendering has started!");
        handleImageLoad();
      } catch (error) {
        console.error('Error initializing camera:', error);
      }
    };

    initializeCamera();

    // Cleanup function
    return () => {
      // Perform cleanup (e.g., stop the camera session) if needed
    };
  }, [onUpdateLens]); // Empty dependency array ensures that the effect runs only once on mount

  const handleImageLoad = () => {
    // Get the dynamically changed canvas size
    const canvas = document.getElementById("my-canvas");
    // const originalWidth = canvas.width;
    // const originalHeight = canvas.height;
  
    // Calculate the new dimensions to maintain the aspect ratio and fit within 300 x 300
    // let newWidth = originalWidth;
    // let newHeight = originalHeight;
    // console.log("Original Dimensions:", originalWidth, originalHeight);
    // if (originalWidth > 300 || originalHeight > 300) {
    //   const aspectRatio = originalWidth / originalHeight;
  
    //   if (originalWidth > originalHeight) {
    //     newWidth = 300;
    //     newHeight = 300 / aspectRatio;
    //   } else {
    //     newHeight = 300;
    //     newWidth = 300 * aspectRatio;
    //   }
    // }
  
    // Set the new dimensions to state
    // setCanvasSize({
    //   width: newWidth,
    //   height: newHeight,
    // });
    // console.log("New Dimensions:", newWidth, newHeight);
  };

    
  return (
    <div className='ckBack'>
      <div className='ckCanvasBorder'>
        {<canvas
          id="my-canvas"

          
        ></canvas>}
        
        {!isLoaded && <div style={{color: '#fff', fontSize: '24px', fontWeight: 'bold' }}> Loading... </div>}
        {isLoaded && <div style={{color: '#fff', fontSize: '24px', fontWeight: 'bold', borderRadius: '8px' }}>
          <br></br>
        <img src={attributionlogo} alt="Attribution"/>
        </div>}
        
      </div>
    </div>
  );
};

export default CameraComponent;
