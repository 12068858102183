import React, { useEffect, useRef } from 'react';

import "./SnapLensComponent.css";

const SnapLensComponent = ({ image, name, onClick }) => {
  return (
    <div className='slc'>
<video src={image} alt={name} autoPlay loop muted playsInline controls={false} disablePictureInPicture />
      <h2>{name}</h2>
      <button onClick={onClick}>Try me</button>
    </div>
  );
};

export default SnapLensComponent;