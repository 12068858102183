import React, { useState, useRef, useEffect } from "react";
import "./Try.css";

import Nav from "../Components/Nav";
import Footer from "../Components/Footer";

import CameraComponent from "../Components/CameraKitModule";
import SnapLensComponent from "../Components/SnapLensComponent";

import DanceVid from "../ArVids/Dance.mp4";
import BaubleVid from "../ArVids/Baubleify.mp4";
import Gumball from "../ArVids/Gumball.mp4";

function TryPage() {
  // Step 1: Lift state up
  const [currentLens, setCurrentLens] = useState(0);
  const [isLensUpdated, setIsLensUpdated] = useState(false);
  const cameraRef = useRef(null);

  // Step 2: Callback function to update currentLens
  const updateLens = (newLens) => {
    setCurrentLens(newLens);
    setIsLensUpdated(true);
  };

  // Step 3: Scroll to CameraComponent on mobile devices
  useEffect(() => {
    if (isLensUpdated) { // && window.innerWidth <= 800
      cameraRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isLensUpdated]);

  return (
    <div className="try-background">
      <div className="try-container">
        <Nav />
        <div className="tryHeader">
          <h1>Try our AR effects!</h1>
          <p>Press "Try me" to try the effect right here in your browser.</p>
        </div>
        <div className="tRow">
          <SnapLensComponent image={BaubleVid} name="Baublify" onClick={() => updateLens(0)} />
          <SnapLensComponent image={DanceVid} name="Gumball Machine" onClick={() => updateLens(1)} />
          <SnapLensComponent image={Gumball} name="Dance Time" onClick={() => updateLens(2)} />
        </div>
        {isLensUpdated && (
          <div ref={cameraRef} style={{ width: '100vw', height: '100vh' }}>
            <CameraComponent currentLens={currentLens} onUpdateLens={() => { }} />
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
}

export default TryPage;