import React from "react";

import "./CardHolder.css";

import PostarComponent from "./PostarComponent";
import yutori from "./postARImages/yutori.png";
import ibasho from "./postARImages/ibasho.png";
import ikigai from "./postARImages/ikigai.png";
import wabisabi from "./postARImages/wabisabi.png";



function PostarHolder() {
  return (

      <div>

        <div className = "product-parent-container">
          <h1 className="product-header">PostARs</h1>
        </div>

    <div className="ch-container">

      <PostarComponent
        image={yutori}
        text={"Yutori"}
        description={"Yutori is a Japanese word that means to take time to relax and unwind."}
        animationDelay={1}
        mainLink={"https://weirdeyestudios.etsy.com/uk/listing/1758786246/japanese-mindfulness-print-yutori-print"}
      />
      <PostarComponent
        image={ibasho}
        text={"Ibasho"}
        description={"Ibasho is a Japanese word that means a place where you feel at home."}
        animationDelay={2}
        mainLink={"https://weirdeyestudios.etsy.com/uk/listing/1773061411/japanese-mindfulness-poster-ibasho-print"}
      />
      <PostarComponent
        image={ikigai}
        text={"Ikigai"}
        description={"Ikigai is a Japanese word that means a reason for being."}
        animationDelay={3}
        mainLink={"https://weirdeyestudios.etsy.com/uk/listing/1758888384/japanese-mindfulness-print-ikigai-poster"}
      />
      <PostarComponent
        image={wabisabi}
        text={"Wabi-Sabi"}
        description={"Wabi-Sabi is a Japanese word that means finding beauty in imperfection."}
        animationDelay={4}
        mainLink={"https://weirdeyestudios.etsy.com/uk/listing/1773078213/wabi-sabi-print-animated-poster-ar"}
      />
    </div>
    </div>
  );
}

export default PostarHolder;