import React from "react";

import "./CardHolder.css";

import CardComponent from "./CardComponent";
import Donut from "./cardImages/DonutCard.png";
import Space from "./cardImages/SpaceCard.png";
import Star from "./cardImages/StarCard.png";
import Heart from "./cardImages/HeartCard.png";
import Party from "./cardImages/PartyCard.png";

function CardHolder() {
  return (

    <div>

    <div className = "product-parent-container">
      <h1 className="product-header">cARds</h1>
    </div>
    <div className="ch-container">
      <CardComponent
        image={Donut}
        text={"Donut Card"}
        description={"This card is perfect for all birthdays!"}
        animationDelay={1}
        mainLink={"https://weirdeyestudios.etsy.com/uk/listing/1736061797/donut-animated-birthday-card-with-audio"}
      />
      <CardComponent
        image={Space}
        text={"Space Card"}
        description={"Give this to someone who is out of this world!"}
        animationDelay={2}
        mainLink={"https://weirdeyestudios.etsy.com/uk/listing/1721890498/space-animated-augmented-reality-card"}
      />
      <CardComponent
        image={Heart}
        text={"Love Card"}
        description={"For that special person in your life!"}
        animationDelay={3}
        mainLink={"https://weirdeyestudios.etsy.com/uk/listing/1736077567/love-heart-augmented-reality-card"}
      />
      <CardComponent
        image={Star}
        text={"Star Card"}
        description={"This card is perfect for all occasions!"}
        animationDelay={4}
        mainLink={"https://weirdeyestudios.etsy.com/uk/listing/1721888526/star-animated-card-with-music-augmented"}
      />
      <CardComponent
        image={Party}
        text={"Party Card"}
        description={"Get the party started with this card!"}
        animationDelay={5}
        mainLink={"https://weirdeyestudios.etsy.com/uk/listing/1736079373/animated-party-card-with-music-party"}
      />
    </div>
    </div>
  );
}

export default CardHolder;