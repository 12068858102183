import React from "react";
import "./Home.css";

import Nav from "../Components/Nav";
import Footer from "../Components/Footer";


import Header from "../Components/Header";
import ClientSection from "../Components/ClientSection";
import Socials from "../Components/Socials";


//images

function CardPage() {
  return (
    <div className="h-background">
      <div className="h-container">
        <Nav />
        <Header />
        <ClientSection />
        <Socials />
        <Footer />
      </div>
    </div>
  );
}

export default CardPage;
