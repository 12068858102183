import React from "react";
import "./Header.css";

import WiggleOne from "../BackgroundBits/WiggleOne.png";
import WiggleTwo from "../BackgroundBits/WiggleTwo.png";

import WiggleOneImage from "../BackgroundBits/WiggleImageOne.png";
import WiggleTwoImage from "../BackgroundBits/WiggleImageTwo.png";

import HomeVid from "./HomeVid";

function Header() {
  return (
    <div className="header-wrapper">
      {" "}
      {/* Wrapper with relative positioning */}
      <div className="header-image-one">
        {" "}
        {/* Container for the floating image */}
        <img
          src={WiggleOneImage}
          alt="Floating Image"
        />
      </div>
      <div className="header-image-two">
        <img
          src={WiggleTwoImage}
          alt="Floating Image"
        />
      </div>
      <div className="header-container">
        <div className="header-text">
          <h1>
            The future starts tomorrow. <br /> Let's get started today.
          </h1>
          <p>
            We specialize in creating high-quality interactive digital
            experiences. From social media AR experiences that captivate
            audiences to immersive games, we can help bring your vision to life.
          </p>
        </div>
        <div className="header-video">
          <HomeVid />
        </div>
      </div>
    </div>
  );
}

export default Header;
