import React from "react";
import "./Privacy.css";

import Nav from "../Components/Nav";
import Footer from "../Components/Footer";





function Privacy() {
  return (
    <div className="privacy-background">
      <div className="privacy-container">
        <Nav />
        <div className="privacy-header">
          <h1>Privacy Policy</h1>
        </div>

        <div className="privacy-content">
          <p>
            Your privacy is important to us. It is Weird Eye Studios' policy to
            respect your privacy regarding any information we may collect from
            you across our website, <a href="http://weirdeyestudios.com">http://weirdeyestudios.com</a>, and other sites we own and operate.
          </p>

          <p>
            Data we collect

            From any of our VR games: (Puzzle Time, Puzzle Time Go, Puzzling Paths, Take Aim, Matching Pairs VR, and any other VR games we may release in the future) we collect the following data:
            - None
            Other than basic analytics data that is collected by the platform that the game is hosted on, we do not collect any data from our games.
            <br></br>
            <br></br>
            From our website, we may collect personal information such as your name, email address, and phone number. This data is collected when you fill out a form on our website, such as the contact form or the newsletter sign-up form.
            <br></br>
            <br></br>
            From Ar-ify (Physical Products) we collect no personal data from our users. We do not collect any data from our users when they use our physical products.


            <br></br>

            For any concerns about the data we collect, please contact us at <a href="mailto:hello@weirdeyestudios.com">hello@weirdeyestudios.com</a>.

          </p>
          </div>

      </div>
    </div>
  );
}

export default Privacy;
