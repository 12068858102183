import React from "react";
import "./CardPage.css";
import Footer from "../Components/Footer";

import Nav from "../Components/Nav";

import CardHeader from "./CardHeader";
import CardHolder from "./CardHolder";
import ReviewSection from "./ReviewSection";

import PostarHolder from "./PostarHolder";

//images

function CardPage() {
  return (
    <div className="cp-background">
      <div className="cp-container">
        <Nav />
        <CardHeader />
        <CardHolder />
        <PostarHolder />
        <ReviewSection />
        <Footer />
      </div>
    </div>
  );
}

export default CardPage;
