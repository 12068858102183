import React from "react";

import "./ServicesHeader.css";

import Why from "./Why";

import DinoExample from "./Media/DinoExample.png";
import LightningExample from "./Media/LightningExample.png";
import ShieldExample from "./Media/ShieldExample.png";

const ServicesHeader = () => {
  return (
    <div className="s-baseHeader">
      <h1>Why choose Weird Eye Studios?</h1>

      <div className="why-container">
        <Why
          image={DinoExample}
          title={"Fun"}
          text={
            "We are no strangers to excitement and fun! We love to make our projects as enjoyable as possible. We are always eager to try out new technologies and ideas."
          }
          animationName="wiggle"
        />
        <Why
          image={LightningExample}
          title={"Exciting"}
          text={"We deliver high quality products that are sure to impress. Users will be amazed by the quality of work and excited to use your product."}
          animationName="wiggle2"
        />
        <Why
          image={ShieldExample}
          title={"Reliable"}
          text={"Deadlines are important to us. We will always deliver on time. We are always talkative during our development process and will keep you updated on our progress."}
          animationName="wiggle3"
        />
      </div>
    </div>
  );
};

export default ServicesHeader;
