import React from 'react';

import './Why.css';

const Why = ({ image, title, text, animationName }) => {
    return (
        <div className='why-base'>
            <img src={image} alt={title} className={animationName ? animationName : ''} />
            <h2>{title}</h2>
            <p>{text}</p>
        </div>
    );
};

export default Why;