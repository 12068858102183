import React from "react";
import "./Socials.css";

import XLogo from "../Media/XLogo.png";
import Lilogo from "../Media/LiLogo.png";
import DFDLogo from "../Media/InstaLogo.png";


function handleXClick() {
  window.open("https://x.com/WeirdEyeStudios", "_blank");
}

function handleLinkedinClick() {
  window.open("https://www.linkedin.com/in/andrew-douglas-352841243/s", "_blank");
}

function handleInstaClick() {
  window.open("https://www.instagram.com/weirdeyestudios/s", "_blank");
}

const Socials = () => {
  return (
    <div className="container-socials">
      <div className="socials-text">
        <h1>Stay Updated</h1>

      </div>
      <div className="social-logos">
      <img src={XLogo} alt="X Logo" className="client-logo" onClick={handleXClick} />
      <img src={Lilogo} alt="Meta Logo" className="client-logo" onClick={handleLinkedinClick} />
      <img src={DFDLogo} alt="Insta Logo" className="client-logo" onClick={handleInstaClick} />
      </div>

    </div>
  );
};

export default Socials;
