import React, { useState } from 'react';
import './ContactField.css';
import axios from 'axios';

const ContactField = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(''); // New state variable for error tracking

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset submission and error states
        setIsSubmitted(false);
        setError('');

        if(!name || !email || !message) {
            setError('All fields are required.');
            return;
        }

        const data = {
          "name": name,
          "email": email,
          "message": message,
          "date": new Date().toISOString()
        };

        try {
          const response = await axios.post("https://api.baserow.io/api/database/rows/table/225751/?user_field_names=true", data, {
            headers: {
              Authorization: "Token t9DVqXn5m3MrIg9U4cKuJR5d1YH4u5Nr",
              "Content-Type": "application/json"
            }
          });

          if (response.status === 200) {
            console.log('Form submitted successfully!');
            setIsSubmitted(true);
          } else {
            console.error('Form submission failed.');
            setError('There was a problem submitting your form. Please try again later.');
          }
        } catch (error) {
          console.error('An error occurred during form submission:', error);
          setError('An error occurred. Please try again later.');
        }

        // Reset the form fields after submitting
        setName('');
        setEmail('');
        setMessage('');
    };

    return (
        <div className="form-container">
            {isSubmitted ? (
                <div className="submission-message">
                    Thank you for your message. We will get back to you soon!
                </div>
            ) : (
                <div>
                    <h1>Lets create the future together!</h1>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        ></textarea>

                        <button type="submit">Submit</button>
                        {error && <div className="error-message">{error}</div>} {/* Display error message if any */}
                    </form>
                </div>
            )}
        </div>
    );
};

export default ContactField;