import React from "react";
import "./ServicePage.css";

import Nav from "../Components/Nav";
import Footer from "../Components/Footer";

import ServicesHeader from "./ServicesHeader";
import ARCSection from "./ARCSection";
import VRCSection from "./VRCSection";

//images

function ServicePage() {
  return (
    <div className="cp-background">
      <div className="cp-container">
        <Nav />
        <ARCSection />
        <VRCSection />
        <ServicesHeader />
        <Footer />
      </div>
    </div>
  );
}

export default ServicePage;
