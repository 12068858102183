import React, { useState, useEffect } from 'react';
import './HomeVid.css';
// Import your media files directly
import ARExampleThree from '../Services/Media/ARExampleThree.gif';
import squarePP from '../Services/vrMedia/squarePP.png';
import squareTA from '../Services/vrMedia/squareTA.png';
import ARExampleOne from '../Services/Media/ARExampleOne.gif';
import exampleVideo from '../Services/vrMedia/squakVid.mp4'; // Example MP4 import

const mediaArray = [
  { type: 'gif', src: ARExampleThree, duration: 5000 },
  { type: 'image', src: squarePP, duration: 5000 },
  { type: 'image', src: squareTA, duration: 5000 },
  { type: 'gif', src: ARExampleOne, duration: 5000 },
  { type: 'video', src: exampleVideo, duration: 30000 }, // Example MP4 entry
  // Add more media as needed
];

const HomeVid = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState('fade-in');

  useEffect(() => {
    setAnimationClass('fade-in');
    const currentMedia = mediaArray[currentIndex];
    const timeout = setTimeout(() => {
      setAnimationClass('fade-out');
      setTimeout(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % mediaArray.length);
      }, 1000); // Fade-out animation duration
    }, currentMedia.duration);

    return () => clearTimeout(timeout);
  }, [currentIndex]);

  const renderMedia = () => {
    const currentMedia = mediaArray[currentIndex];
    if (currentMedia.type === 'video') {
      return (
        <video src={currentMedia.src} alt="" className={`media ${animationClass}`} autoPlay loop muted />
      );
    } else {
      return (
        <img src={currentMedia.src} alt="" className={`media ${animationClass}`} />
      );
    }
  };

  return (
    <div className="media-container">
      {renderMedia()}
    </div>
  );
};

export default HomeVid;