import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Nav.css';



import WESLogo from '../Media/WESLogo.png';

const Nav = () => {

    const navigate = useNavigate(); // Step 2: Use the useNavigate hook
    return (
        <nav>
            <div className='left'>
                <img src={WESLogo} alt="Floating Image" className='nav-image' />
                <Link to="/" className="nav-wes-link">Weird Eye Studios</Link>
            </div>
            <div className='right'>
                    <button onClick={() => {navigate('/physical'); }}>Products</button>
                    <button onClick={() => {navigate('/services'); }}>Services</button>
                    <button onClick={() => {navigate('/try'); }}>Try</button>
                    <button onClick={() => {navigate('/contact'); }}>Contact</button>
</div>
        </nav>
    );
};

export default Nav;