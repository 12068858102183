import React from 'react';
import './CardHeader.css';

import cardBackground from './cardBackground.png';
import CardHGif from './CardHGif.gif';

const CardHeader = () => {
    return (
        <div className="card-header">
            <div className='card-header-text'>
            <h1>
                Physical AR Products
            </h1>
            <p>
                Check out our range of physical AR products.                <br></br>
                We sell cARds - a range of physical cards that come to life with AR.                <br></br>
                These are ideal for those special occasions.
                <br></br>
                <br></br>
                We also sell postARs - a range of posters that come to life with AR.                <br></br>
                These are perfect for brightening up any room and designed with mindfullness in mind.

            </p>
            </div>
            <div className='card-header-image'>
                <div className='gif-wrapper'>
                <img src={CardHGif} alt="Card Header Gif" className='card-image-gif' />
                </div>
                <img src={cardBackground} alt="Card Background" className='card-image-base'/> 
            </div>
        </div>
    );
};

export default CardHeader;