import React from "react";
import "./ContactPage.css";

import Nav from "../Components/Nav";
import ContactForm from "./ContactField";
import Footer from "../Components/Footer";

import square from "./square.png";
import blast from "./blast.png";

//images

function ContactPage() {
  return (
    <div className="co-background">
      <div className="co-container">
        <Nav />
        <ContactForm />
        <div className="contact-square">
          <img src={square} alt="Square" />
        </div>
        <div className="contact-blast">
          <img src={blast} alt="Blast" />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ContactPage;
