import React from "react";
import "./ARCSection.css";


//images
import ArExample from "./Media/ARExampleOne.gif";
import ArExampleTwo from "./Media/ARExampleTwo.gif";
import ArExampleThree from "./Media/ARExampleThree.gif";

function ARCSection() {
  return (
    <div className="ARC-base">
      <div className="arc-text">
      <h1>Social AR</h1>
      <p>With well over 200 million plays on our AR effects and lenses we have the skills required to create a wonderful and unique experience for your brand.</p>
      </div>
      <div className="arc-img">
        <img src={ArExample} alt="AR Example" />
        <img src={ArExampleTwo} alt="AR Example Two" />
        <img src={ArExampleThree} alt="AR Example Two" />
        </div>
    </div>
  );
}

export default ARCSection;
