import React from "react";
import "./CardComponent.css";

const CardComponent = ({ image, text, description, animationDelay, mainLink }) => {
  // Inline style for animation
  const adjustedAnimationDelay = (animationDelay - 1) * 2;
  const animationStyle = {
    animation: `moveUpDown 10s ease-in-out ${adjustedAnimationDelay}s infinite` // 2s movement + 10s delay
  };

  return (
    <div className="card-base" >
      <img src={image} alt="Card Image" className="cardImg" style={animationStyle}/>
      <p className="card-text">{text}</p>
      <p className="card-desc">{description}</p>
      <a href={mainLink} className="card-link" target="_blank" rel="noopener noreferrer">Purchase</a>
    </div>
  );
};

export default CardComponent;