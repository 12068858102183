import React from "react";
import "./VRCSection.css";

//images
import MP from "./vrMedia/squareMP.png";
import PP from "./vrMedia/squarePP.png";
import PT from "./vrMedia/squarePT.png";
import TA from "./vrMedia/squareTA.png";

function VRCSection() {
  return (
    <div className="vrC-base">
      <div className="vrc-img">
        <img src={MP} alt="AR Example" />
        <img src={PP} alt="AR Example Two" />
        <img src={PT} alt="AR Example Two" />
        <img src={TA} alt="AR Example Two" />
      </div>
      <div className="vrc-text">
        <h1>Immersive VR</h1>
        <p>
          We pride ourselves on pushing the boundaries of virtual reality
          gaming. 
          With a portfolio that boasts over four successful VR games, we
          have captivated audiences across three different platforms.
        </p>
      </div>
    </div>
  );
}

export default VRCSection;
