import React from "react";
import "./ReviewSection.css";

import ReviewComponent from "./ReviewComponent";
import SpaceCard from "./cardImages/SpaceCard.png";
import PartyCard from "./cardImages/PartyCard.png";

import SThree from "./cardImages/SThree.png";

const ReviewSection = () => {
  return (
    <div>
      <div className="rs-header-holder">
        <div className="rs-header-background">
          <h1 className="rs-header">Reviews</h1>
        </div>
      </div>
      <img src={SThree} alt="Three Stars" className="rs-squigle" />
    <div className="rs-container">
      <ReviewComponent
        image={SpaceCard}
        text="Really cool card I bought for Father’s Day. It has such a unique design with being able to bring it to life through your phone. High quality and quick delivery, thanks Andrew!"
      />

<ReviewComponent
        image={SpaceCard}
        text="What an amazing new, fun use of technology for what is an old product like a greetings card. AR really brings the card to life with movement and music and is soooo easy to use. Arrived super prompt too. Thanks."
      />

<ReviewComponent
        image={PartyCard}
        text="This is such a fun birthday card for a teenager. The AR effects were really easy to generate and the card was high quality. Would recommend."
      />


    </div>

    </div>
  );
};

export default ReviewSection;
