import React from "react";
import "./ClientSection.css";

import SnapLogo from "../Media/SnapLogo.png";
import MetaLogo from "../Media/MetaLogo.png";
import DFDLogo from "../Media/DFDLogo.png";

const ClientSection = () => {
  return (
    <div className="container-client">
      <div className="client-text">
        <h1>Brands We've Helped Prepare for Tomorrow</h1>
        <p>
          Our clients are our partners. We work with them to create the best
          possible digital experiences for their customers. We are proud to have
          worked with some of the biggest names in the industry.
        </p>
      </div>
      <div className="client-logos">
        <img src={SnapLogo} alt="Snapchat Logo" className="client-logo" />
        <img src={MetaLogo} alt="Snapchat Logo" className="client-logo" />
        <img src={DFDLogo} alt="Snapchat Logo" className="client-logo" />
      </div>
    </div>
  );
};

export default ClientSection;
